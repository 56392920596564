export const convertBase64ToBlob =(base64: string, mime_ctype: string) =>  {
  // 日本語の文字化けに対処するためBOMを作成する。
  var bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
  var bin = atob(base64.replace(/^.*,/, ''));
  var buffer = new Uint8Array(bin.length);
  for (var i = 0; i < bin.length; i++) {
      buffer[i] = bin.charCodeAt(i);
  }
  // Blobを作成
  try {
      var blob = new Blob([bom, buffer.buffer], {
          type: mime_ctype,
      });
  } catch (e) {
      return false;
  }
  return blob;
}