import './firebase/index';
import * as React from 'react';
import { Provider } from 'react-redux';
import { store } from './redux';
import { Pages } from './components/pages/Pages';
import { FirebaseToRedux } from './firebase/FirebaseToRedux';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { GlobalStyle } from "./styles/GlobalStyle";
import { ThemeProvider } from "styled-components";
import { theme } from "./styles/theme";
import { ThemeProvider as MaterialThemeProvider, StyledEngineProvider} from '@mui/material/styles';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { Header } from './components/organisms/Header';
import { Footer } from './components/atoms/Footer';
import { Spacer } from './components/atoms/Spacer';
import { MediaQueryProvider } from './components/provider/MediaQueryProvider';
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';

const persistor = persistStore(store)

function App() {
  return (
    <MediaQueryProvider>
    <StyledEngineProvider injectFirst>
    <MaterialThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
    <HelmetProvider>
    <SnackbarProvider maxSnack={10}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <FirebaseToRedux />
      <Router>
        <Header />
        <Spacer height={48} spHeight={81} />
        <Pages/>
        <Footer/>
      </Router>
      </PersistGate>
      </Provider>
      </SnackbarProvider>
    </HelmetProvider>
    </ThemeProvider>
    </MaterialThemeProvider>
    </StyledEngineProvider>
    </MediaQueryProvider>
  );
}

export default App;
