import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Customer } from '../../../common-types/customer';

export type StateCustomer = Omit<Customer, 'createAt' | 'updateAt'> &
{
  customerId: string,
  createAtMillis: number,
  updateAtMillis: number | null
};

export const initialState: StateCustomer  = {
  customerId: '',
  role: 'user',
  organizationId: '',
  email: '',
  name: '',
  createAtMillis: 0,
  updateAtMillis:  null,
  createdBy: '',
  updatedBy: ''
}

const customerDataSlice = createSlice({
  name: 'customerData',
  initialState,

  reducers: {
    setCustomerData: (_state: StateCustomer, action: PayloadAction<StateCustomer>) => {
      return action.payload;
    },
    resetCustomerData: () => {
      return initialState;
    }
  }
});

export const { setCustomerData, resetCustomerData } = customerDataSlice.actions;
export const customerDataReducer = customerDataSlice.reducer;
