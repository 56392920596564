import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { loginedReducer } from './logined';
import { createSelectorHook, useDispatch } from 'react-redux';
import { customerDataReducer } from './customerData';
import { itemsReducer } from './items';
import { algoliaQueryReducer } from './algoliaQuery';
import { cartReducer } from './cart';
import { organizationReducer } from './organization';
import { newsReducer } from './news';
import { orderReducer } from './order';
import { customerListReducer } from './customerList';
import { mfBillingsReducer } from './mfbillings';
import { multiplicationRatesReducer } from './multiplicationRates';
import { initReadReducer } from './initRead';
import { stocksReducer } from './stocks';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['cart']
}

// NOTE: https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
const reducer = combineReducers({
  logined: loginedReducer,
  customerData: customerDataReducer,
  items: itemsReducer,
  algoliaQuery: algoliaQueryReducer,
  cart: cartReducer,
  organization:organizationReducer,
  news:newsReducer,
  order: orderReducer,
  customerList: customerListReducer,
  mfBillings: mfBillingsReducer,
  multiplicationRates:multiplicationRatesReducer,
  initRead: initReadReducer,
  stocks: stocksReducer
});
const persistedReducer = persistReducer(persistConfig, reducer)
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      // Redux-ToolkitにFirebaseのTimestampをいれるときのエラー処理
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, 'customerData/setCustomerData', 'customerDataList/setCustomerList', 'organization/setOrganization', 'news/setNews', 'order/setOrder', 'cart/onChangeCart', 'cart/resetCart', 'multiplicationRates/setMultiplicationRates'],
    },
  }),
});
type RootState = ReturnType<typeof reducer>
export const useTypedSelector = createSelectorHook<RootState>();
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
