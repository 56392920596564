import React from 'react';
import styled from "styled-components"

type Props = {
  height: number;
  spHeight?: number;
};

export const Spacer = ({ height, spHeight }: Props) => {
  return <Div height={height} spHeight={spHeight} />;
};

const Div = styled.div<Props>`
  width: 100%;
  height: ${({height })=> `${height}px`};
  @media screen and (max-width: 650px) {
    height: ${({spHeight })=> `${spHeight}px`};
}
`