import { StringLike } from '@firebase/util';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MfBilling } from 'common-types/organization';

export type StateMfBilling = Omit<MfBilling, 'createAt' | 'updateAt'> &
{
  createAtMillis: number,
  updateAtMillis: number,
  mfbillingId: StringLike
};

export const initialState: StateMfBilling[]= [{
    pdfUrl: '',
    billingId: '',
    createAtMillis: 0,
    updateAtMillis: 0,
    mfbillingId: ''
}]


const mfBillingsSlice = createSlice({
    name: 'mfBillings',
    initialState,
    reducers: {
        setMfBillings: (_state: StateMfBilling[], action: PayloadAction<StateMfBilling[]>) => {
          return action.payload;
        },
        resetMfBillings: () => {
          return initialState;
        }
      }
  });
  
  export const { setMfBillings, resetMfBillings } = mfBillingsSlice.actions;
  export const mfBillingsReducer = mfBillingsSlice.reducer;
  