import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#EC663B',
    },
    secondary: {
      main: '#D0CFCF',
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: ['Noto Sans JP', 'sans-serif'].join(','),
  },
});

export default theme;
