import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Order } from 'common-types/order';

const initialState: StateOrder[] = []

export type StateOrder = Omit<Order, 'createAt' | 'updateAt'> &
{
  id: string,
  createAtMillis: number,
  updateAtMillis: number | null
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrder: (stateOrder: StateOrder[], action: PayloadAction<StateOrder[]>) => {
        return action.payload
      },
    modifiedOrder: (stateOrder: StateOrder[], action: PayloadAction<StateOrder>) => {
      const filteredState = stateOrder.filter(o=> o.id !== action.payload.id)
        return [...filteredState, action.payload]
      },
    removeOrder: (stateOrder: StateOrder[], action: PayloadAction<string>) => {
      const filteredState = stateOrder.filter(o=> o.id === action.payload)
        return filteredState
      },
    resetOrder: () => {
      return initialState
    }
    }
  }
);

export const { setOrder, modifiedOrder, removeOrder, resetOrder } = orderSlice.actions;
export const orderReducer = orderSlice.reducer;
