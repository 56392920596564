import styled from 'styled-components';
import { privacyPolicyUrl, termUrl } from '../../config';
import { useTypedSelector } from '../../redux';

export const Footer = ()=>{
  const logined = useTypedSelector((state) => state.logined);
  return(
    <>
    {logined ?
    <Wrap>
      <ContentWrap>
        <Content onClick={()=> window.open(termUrl, '_blank')}>利用規約</Content>
        <Content onClick={()=> window.open(privacyPolicyUrl, '_blank')}>プライバシーポリシー</Content>
        <Company>@TECIDO</Company>
      </ContentWrap>
    </Wrap> : <></>}
    </>
  )
}

const Wrap = styled.div`
border-top: 1.5px solid rgba(0, 0,0,0.1);
width: 100%;
height: 56px;
position: fixed;
bottom: 0;
display: flex;
align-items: center;
background: white;
z-index:99;
`;

const ContentWrap = styled.div`
font-weight: 600;
font-family: Roboto;
font-size: 14px;
margin-left: 175px;
color:#5B5A59;
display: flex;
position: relative;
z-index:99;
width: calc(100% - 24px);
@media screen and (max-width: 650px) {
  font-size: 12px;
  margin-left: 12px;
  margin-right: 12px;
  width: 100%;
}
`;

const Content = styled.div`
margin-left:44px;
cursor: pointer;
@media screen and (max-width: ${(props) =>
  props.theme.breakpoints.values.sm}px) {
font-size:12px;
margin-left: 16px;
letter-spacing: 0;
}
`;

const Company = styled.div`
font-size:12px;
position: absolute;
right: 175px;
font-weight: 100;    
letter-spacing: 0;
@media screen and (max-width: ${(props) =>
  props.theme.breakpoints.values.sm}px) {
    right: 0;
}
`;
