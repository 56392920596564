import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import styled from "styled-components"
import {CompanyIcon} from '../../icons/SvgIcon';

type IconSettingsProps = {
  menu1: () => void,
  menu2: () => void,
  menu3: () => void,
  menu4: () => void,
  menu5: () => void,
  settings: String[]
}

export const  ResponsiveAppBar = (props:IconSettingsProps) => {
  const {
    menu1,
    menu2,
    menu3,
    menu4,
    menu5,
    settings
  } = props;

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <Box >
        <Tooltip title="Open settings">
          <IconButton onClick={handleOpenUserMenu} >
            <CompanyIcon />
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: '45px' }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <MenuItem onClick={menu1}>
            <Typography textAlign="center">{settings[0]}</Typography>
          </MenuItem>
          <MenuItem onClick={menu2}>
            <Typography textAlign="center">{settings[1]}</Typography>
          </MenuItem>
          <MenuItem onClick={menu3}>
            <Typography textAlign="center">{settings[2]}</Typography>
          </MenuItem>
          <MenuItem onClick={menu4}>
            <Typography textAlign="center">{settings[3]}</Typography>
          </MenuItem>
          <MenuItem onClick={menu5}>
            <Typography textAlign="center">{settings[4]}</Typography>
          </MenuItem>
        </Menu>
      </Box>
    </>
  );
};

export default ResponsiveAppBar;
