import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider, connectAuthEmulator, setPersistence, browserSessionPersistence } from 'firebase/auth';
import { firebaseConfig } from '../config';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

export const firebaseApp = initializeApp(firebaseConfig);
export const firestore = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp);
setPersistence(auth, browserSessionPersistence);
export const googleProvider = new GoogleAuthProvider();
export const functions = getFunctions(firebaseApp, 'asia-northeast1');


if (window.location.hostname === "localhost" /*&& window.location.port !== '3000'*/) {
   connectFirestoreEmulator(firestore, 'localhost', 8080);
   connectAuthEmulator(auth, "http://localhost:9099");
   connectFunctionsEmulator(functions, 'localhost', 5001);
}