import styled from 'styled-components';
import Typography from '@mui/material/Typography';

export const Title = styled(Typography).attrs({
  variant: 'h5',
})`
  font-weight: 700;
  @media screen and (max-width: 650px) {
  font-size: 18px;
}
`;
export const SubTitle = styled(Typography).attrs({
  variant: 'h6',
})`
  font-weight: 700;
`;
export const Text = styled(Typography).attrs({
  variant: 'body1',
})<{marginLeft?: string;}>`
  font-weight: 400;
  margin-left: ${({marginLeft})=> marginLeft ?? "0px"};
  @media screen and (max-width: 650px) {
    font-size: 12px;
  }
`;
export const UnderLineGleyText = styled(Typography).attrs({
  variant: 'body1',
})`
  font-weight: 700;
  color: #5B5A59;
  text-decoration-line: underline;
`;
export const Caption = styled(Typography).attrs({
  variant: 'caption',
})`
  font-weight: 300;
`;
export const UnderLineRedText = styled(Typography).attrs({
  variant: 'subtitle1',
  color: 'primary',
})`
  font-weight: 700;
  text-decoration-line: underline;
  @media screen and (max-width: 650px) {
    font-size: 14px;
  }
`;
