import * as React from 'react';
import styled from "styled-components";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ResponsiveAppBar from '../atoms/IconSettings'
import { Link } from 'react-router-dom';
import { useTypedSelector} from '../../redux';
import { logout } from '../../firebase/auth';
import { logout as reduxLogout } from '../../redux/logined';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router";
import { arukabeManualUrl, tayoriFormUrl } from '../../config';
import { Typography } from '@mui/material';

export const BasicTabs = () => {
  const [value, setValue] = React.useState(0);
  const logined = useTypedSelector((state) => state.logined);
  const organization = useTypedSelector((state) => state.organization);
  const dispatch = useDispatch();
  const history = useHistory();
  const settings = ['企業情報', 'アカウント管理', '請求書', 'お問い合わせ', 'ログアウト'];

  const Company = () => {
    history.push(`/settings/company`);
  }

  const Account = () => {
    history.push(`/settings/account`);
  }
  const Invoice = () => {
    history.push(`/invoice`);
  }
  
  const Contact = () => {
    window.open(tayoriFormUrl, '_blank')
  }
  
  const Logout = async () => {
    await logout()
      .then(() => {
        dispatch(reduxLogout());
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if(newValue === 3) return
    setValue(newValue);
  };
  return (
    <>
      {logined?
        <>
          <Box ml={'auto'} mr={0}>
            <TabsWrap value={value} onChange={handleChange} TabIndicatorProps={{
                  style: {
                      height: '4px'
                  }
              }} >
                <Tab label="商品情報一覧" component={Link} to='/' style={{fontWeight: 600, color: '#B1B0AE'}}/>
                <Tab label="カート" component={Link} to='/cart'  style={{fontWeight: 600, color: '#B1B0AE'}}/>''
                <Tab label="注文履歴" component={Link} to='/order-history' style={{fontWeight: 600, color: '#B1B0AE'}}/>
                <Tab label="操作マニュアル" onClick={()=> window.open(arukabeManualUrl,'_blank')} style={{fontWeight: 600, color: '#B1B0AE'}}/>
            </TabsWrap>
          </Box>
          <Wrapper>
          <div style={{width: 150}}>
          <Typography  style={{fontWeight: 600, color: '#B1B0AE', fontSize: 12}}>{organization?.name ? `${organization?.name}様` : ''}</Typography>
          </div>
          <ResponsiveAppBar
            menu1={Company}
            menu2={Account}
            menu3={Invoice}
            menu4={Contact}
            menu5={Logout}
            settings={settings}
          />
          </Wrapper>
        </>
      : null}
    </>
  );
}

const TabsWrap = styled(Tabs)`
  margin: ${props => props.theme.spacing(4)}px;
  @media screen and (max-width: 650px) {
    display: none;
  }
`;

const Wrapper = styled.div`
  margin-right: 30px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 650px) {
    display: none;
  }
`;


export default BasicTabs;