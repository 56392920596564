import Paper from '@mui/material/Paper';
import styled from 'styled-components';

export const FullPaper = styled(Paper)`
  margin: ${(props) => props.theme.spacing(2)};
  padding: ${(props) => props.theme.spacing(4)};
  padding-bottom: ${(props) => props.theme.spacing(20)};
  border-radius:12px;
  width: calc(100% - ${(props) => props.theme.spacing(3)} * 2);
  max-width: 1240px;
  margin: 0 auto;
  box-shadow: none;
  @media screen and (max-width: 650px) {
    margin: ${(props) => props.theme.spacing(0)};
    padding: 0 ${(props) => props.theme.spacing(2)};
    width: 100%;
  }

`;
export const CustomizedPaper = styled(Paper)`
  margin: ${(props) => props.theme.spacing(2)} 0
    ${(props) => props.theme.spacing(2)}
    ${(props) => props.theme.spacing(2)};
  padding: ${(props) => props.theme.spacing(0)};
  box-shadow: none;
  border-radius:12px;
  width: calc(90% - ${(props) => props.theme.spacing(3)} * 4);
  max-width: 1240px;
  overflow: hidden;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}) {
    width: calc(90% - ${(props) => props.theme.spacing(3)} * 4);
  }
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 650px) {
    margin: ${(props) => props.theme.spacing(0)};
    padding: 0 ${(props) => props.theme.spacing(2)};
    width: 100%;
  }
`;

export const ItemPaper = styled(Paper)`
  border-radius:12px;
  padding: ${(props) => props.theme.spacing(2)} ${(props) => props.theme.spacing(5)};
`;


export const FlexWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const FlatFullPaper = styled(Paper)`
  margin: ${(props) => props.theme.spacing(2)};
  width: calc(100% - ${(props) => props.theme.spacing(2)} * 2);
  border-radius:12px;
  box-shadow: none;
`;

export const FlexWrapCenter = styled.div`
  justify-content: space-between;
  width:100%;
  display: flex;
  flex-wrap: wrap;
`

export const StyledFullPaper = styled(Paper)`
  margin:${props => props.theme.spacing(2)};
  padding:${props => props.theme.spacing(4)};
  width:calc( 100% - ${props => props.theme.spacing(3)} * 2);
  max-width: 1240px;
  border-radius:12px;
  box-shadow: none;
  @media screen and (max-width: ${props => props.theme.breakpoints.values.sm}){
    padding: 0;
    margin:0;
    width:calc( 100% - ${props => props.theme.spacing(1)} * 2);
    margin: ${props => props.theme.spacing(1)};
    margin-bottom:${props => props.theme.spacing(8)};
  }
`;

export const StyledFlexWrap = styled.div`
  width:100%;
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: ${props => props.theme.breakpoints.values.sm}){
    padding: 0;
  }
`;

export const StyledHalfFlexWrap = styled.div`
width: 682px;
display: flex;
flex-wrap: wrap;
@media screen and (max-width: 1000px){
  width: 100%
}
`;

export const QuarterStyledePaper = styled(Paper)`
width : 375px;
box-shadow: none;
padding: 0;
margin:${props => props.theme.spacing(2)};
padding:${props => props.theme.spacing(1)};
width:calc( 100% - ${props => props.theme.spacing(3)} * 2);
@media screen and (max-width: ${props => props.theme.breakpoints.values.sm}){
  padding: 0;
  width : 375px;
  margin:0;
  box-shadow: none;
  margin-bottom:${props => props.theme.spacing(8)};
}
`;

export const CenteredPaper = styled(Paper)`
width : 486px;
height: 500px;
box-shadow: none;
border-radius: 4px;
text-align:center;
border: solid 1px #DADCE0;
margin: 150px auto auto auto;
@media screen and (max-width: ${props => props.theme.breakpoints.values.sm}){
  padding: 0;
  width : 100vw;
  marign: 0;
  border:none;
  height: auto;
}
`;

export const HeaderPaper = styled(Paper)`
z-index: 100;
position: fixed;
width : 100%;
height: 50px;
box-shadow: none;
text-align:center;
align-items: center;
display: flex;
border: solid 1px #DADCE0;
@media screen and (max-width: 650px){
  padding: 0;
  width : 100vw;
  marign: 0;
  border:none;
  height: auto;
};
`;
