import { firestore } from '../index';
import { Customer } from '../../../../common-types/customer';
import { collection, query, where, getDocs, doc, updateDoc, deleteDoc } from "firebase/firestore";

export const customerRef = collection(firestore, 'customers');

export const updateCustomerRef = async (value: Customer, role: string) => {
  const q = await query(customerRef, where("email", "==", value.email), where("organizationId", "==", value.organizationId));
  const customerSnapShot = await getDocs(q);
  customerSnapShot.forEach( async (document) => {
    const customerDocumentRef = doc(firestore, "customers", document.id);
    await updateDoc(customerDocumentRef, {
      role: role
    })
  })
};

export const deleteCustomerRef = async (value: Customer) => {
  const q = await query(customerRef, where("email", "==", value.email), where("organizationId", "==", value.organizationId));
  const customerSnapShot = await getDocs(q);
  customerSnapShot.forEach( async (document) => {
    await deleteDoc(doc(firestore, "customers", document.id));
  })
};