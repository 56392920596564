import  { lazy, Suspense } from 'react';
import {  Route, Switch } from 'react-router-dom';
import { useTypedSelector } from '../../redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import styled from 'styled-components';
import { useMediaQueryContext } from '../provider/MediaQueryProvider';
import NotFoundPage from './NotFoundPage';
import { SimpleBackdrop } from '../atoms/Backdrop';
import InvoicePage from './InvoicePage';

const LoginPage = lazy(() => import('./LoginPage'));
const TopPage = lazy(() => import('./TopPage'));
const AccountPage = lazy(() => import('./settings/AccountPage'));
const UserNamePage = lazy(() => import('./settings/UserNamePage'));
const PasswordPage = lazy(() => import('./settings/PasswordPage'));
const CompanyPage = lazy(() => import('./settings/CompanyPage'));
const ItemDetailPage = lazy(() => import('./items/ItemDetailPage'));
const NewsDetailPage = lazy(() => import('./news/NewsDetailPage'));
const CartPage = lazy(() => import('./cart/IndexPage'));
const ConfirmPage = lazy(() => import('./cart/ConfirmPage'));
const CartFinishPage = lazy(() => import('./cart/CartFinishPage'));
const OrderHistoryIndexPage = lazy(() => import('./orderHistory/IndexPage'));
const OrderHistoryDetailPage = lazy(() => import('./orderHistory/DetailPage'));
const CreateInitTokenPage = lazy(() => import('./CreateInitTokenPage'));
const MaintenancePage = lazy(() => import('./MaintenancePage'));
const NewsListPage = lazy(() => import('./news/NewsListPage'));

const pages:Page[] = [
  { path: '/', component: <TopPage/> ,isPubulic: false},
  { path: '/login', component: <LoginPage/> ,isPubulic:true},
  { path: '/settings/account', component: <AccountPage/> ,isPubulic:false},
  { path: '/settings/account/username', component: <UserNamePage/> ,isPubulic:false},
  { path: '/settings/account/password', component: <PasswordPage/> ,isPubulic:false},
  { path: '/settings/company', component: <CompanyPage/> ,isPubulic:false},
  { path: '/items/:id', component: <ItemDetailPage/> ,isPubulic:false},
  { path: '/news/:id', component: <NewsDetailPage/> ,isPubulic:false},
  { path: '/news-list', component: <NewsListPage/> ,isPubulic:false},
  { path: '/invoice', component: <InvoicePage/> ,isPubulic:false},
  { path: '/cart', component: <CartPage/> ,isPubulic:false},
  { path: '/cart/confirm', component: <ConfirmPage/> ,isPubulic:false},
  { path: '/cart/finish', component: <CartFinishPage/> ,isPubulic:false},
  { path: '/order-history', component: <OrderHistoryIndexPage/> ,isPubulic:false},
  { path: '/order-history/:id', component: <OrderHistoryDetailPage/> ,isPubulic:false},
  { path: '/create-initToken', component: <CreateInitTokenPage/> ,isPubulic:true},
  { path: '/maintenance', component: <MaintenancePage/> ,isPubulic:false},
];


type Page = {
  path:string;
  component:JSX.Element;
  isPubulic:boolean;
}

export const Pages = () => {
  const logined = useTypedSelector((state) => state.logined);
  const initRead = useTypedSelector((state) => state.initRead);
  const checkAuth = (isPublic:boolean,page:any) => {
    if(isPublic){
      return page;
    }else{
      return logined ? page : <LoginPage/>;
    }
  }
  const { isMobileSite } = useMediaQueryContext();
  if (isMobileSite) {
    return (
      <div style={{marginBottom: "81px"}}>
        <Switch>
        {pages.map((item, index) => (
          <Route
            key={index}
            exact
            path={item.path}
            component={() => <Suspense fallback={<LinearProgress />}>{checkAuth(item.isPubulic,item.component)}</Suspense>}
          />
        ))}
        <Route component={() => <NotFoundPage/>} /> 
        </Switch>
      </div>
    )
  }
  return (
    <Switch>
      {initRead ? pages.map((item, index) => (
        <Route
          key={index}
          exact
          path={item.path}
          component={() => <Suspense fallback={<LinearProgress />}>{checkAuth(item.isPubulic,item.component)}</Suspense>}
        />
      )) : <SimpleBackdrop open={true} message={'読み込み中..'}/>}
       <Route component={() => <NotFoundPage/>} /> 
    </Switch>
  );
};
