import { BasicTabs } from '../molecules/Tabs'
import { HeaderPaper } from '../atoms/Paper'
import { SpMenu } from "../molecules/SpMenu"
import { ArukabeIcon } from '../../icons/SvgIcon';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { searchAlgoria } from '../../algolia';
import { Item } from 'common-types/item';
import { setItems } from '../../redux/items';
import { useDispatch } from 'react-redux';
import { setAlgoliaQuery } from '../../redux/algoliaQuery';

export const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const onClickArukabeIcon = async() => {
    if(location.pathname === '/'){
      dispatch(setItems([]));
    }
    history.push('/');
    dispatch(setAlgoliaQuery({
      query: '',
      page: 1,
      restrictSearchableAttributes: ['']
    }));
  }
  return (
    <HeaderPaper>
        <Div onClick={onClickArukabeIcon}>
          <ArukabeIcon/>
        </Div>
        <BasicTabs/>
        <SpMenu />
    </HeaderPaper>
  )
}

const Div = styled.div`
  margin:0 ${props => props.theme.spacing(4)};
  @media screen and (min-width: ${(props) =>
    props.theme.breakpoints.values.sm}) {
  }
  cursor: pointer;
`;