import { NoHistory } from '../../icons/SvgIcon';
import { FullPaper } from '../atoms/Paper';
import { Title} from '../atoms/Typography';
import styled from 'styled-components';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { Button, TableHead } from '@mui/material';
import React, {useEffect, useState, useMemo} from 'react';
import { useTypedSelector } from '../../redux';
import { SimpleBackdrop } from '../atoms/Backdrop';
import functions from '../../firebase/functions';
import { convertBase64ToBlob } from '../../utils/convertBase64ToBlob';
import { query, where, getDoc, orderBy, getDocs, endAt, doc } from 'firebase/firestore';
import dayjs from '../../lib/dayjs';
import { orderRef } from '../../firebase/firestore/order';
import { exportCSV } from '../../utils/exportCSV';
import { Order } from 'common-types/order';
import { firestore } from '../../firebase';
import { Organization } from 'common-types/organization';

const InvoicePage = () => {
  // 対象の請求書のパスからデータを取得する
  const mfBillings = useTypedSelector((state) => state.mfBillings);
  const organization = useTypedSelector((state) => state.organization);
  const customer = useTypedSelector((state) => state.customerData);
  const [disabled, setDisabled] = useState<boolean>(false);

  const onClickDownloadBilling = async(yearSelectValue: string) => {
    if(!yearSelectValue){
      window.alert('対象月を選んでください');
      return;
    }
    setDisabled(true);
    const response = await functions.getMFBilling(yearSelectValue).catch((err: Error) => {
      window.alert('請求書取得に失敗しました');
      setDisabled(false);
      return;
    });
    if(!response){
      window.alert('請求書取得に失敗しました');
      setDisabled(false);
      return;
    }
    const base64 = response.data;
    if(!base64){
      window.alert('請求書取得に失敗しました');
      setDisabled(false);
      return;
    }
    var blob = convertBase64ToBlob(base64 as string, "application/pdf");
    if(blob){
      let url = (window.URL || window.webkitURL).createObjectURL(blob);
      let link = document.createElement('a');
      link.download = `請求書_${organization.name}様_${yearSelectValue.replace('-', '/')}月分.pdf`;
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    setDisabled(false);
  }

  const onClickDonwloadCSV = async(yearSelectValue: string) =>{
    const yyyymm = dayjs(yearSelectValue).format('YYYYMM');
    const dd = organization.paymentTerm.slice(0,2);
    const endDate = dayjs(`${yyyymm}${dd}`).endOf('day').toDate();
    const prevMonthEndDate = dayjs(`${yyyymm}${dd}`).endOf('day').subtract(1, 'month');
    const q = query(orderRef, where('billingAddress', '==', customer.organizationId), where('billingStatus','==','delivered'),orderBy('createAt', 'desc'));
    const orderDocs = await getDocs(q);
    const list: String[][]= [];
    list.push([`${organization.name} 御中`]);
    list.push([`〒${organization.postalCode}`]);
    list.push([`件名:請求書${dayjs(yearSelectValue).format('YYYY年MM月')}`]);
    list.push([`${organization.prefecture} ${organization.address}`]);
    list.push(['品名', 'お客様注文番号', '注文日', '単価', '数量', '価格'])
    let count: number = 0;
    // shippingDataが前回の請求日以降のものを抽出
    const sortedOrder = orderDocs.docs.filter((doc) => {
      return doc.data().shippingDate !== "" && dayjs(doc.data().shippingDate).unix() > prevMonthEndDate.unix() && doc.data().billingStatus === 'delivered' && dayjs(doc.data().shippingDate).unix()  <= dayjs(endDate).unix();
    }).sort((a,b) => dayjs(a.data().shippingDate).unix() > dayjs(b.data().shippingDate).unix() ? 1 : -1);
    // 支店IDをstring[]
    const branchIds = orderDocs.docs.map((doc) =>  {
      const order = doc.data() as Order;
      return order.organizationId;
    }).filter((elem, index, self) => {
      return self.indexOf(elem) === index;
    });
    const organizationDatas = await Promise.all(branchIds.map(async (branchId) => {
       const orgData = await getDoc(doc(firestore, `organizations/${branchId}`))
       const org = orgData.data() as Organization;
       return {
        ...org,
        id: branchId,
       }
    }));
    sortedOrder.forEach((doc) => {
      const order = doc.data() as Order;
        if(order.items.length > 0){
          order.items.forEach((item) => {
            count = count + item.price * item.counts;
            const branchName =  organizationDatas.find((org) => org.id === order.organizationId)?.name;
            // 視点のオーダ
            list.push([item.itemNumber, `${order.orderNumber ? order.orderNumber : 'お客様番号未記入'}: ${branchName}`, order.shippingDate ? dayjs(order.shippingDate).format('YYYY年MM月DD日') : "", String(item.price), String(item.counts), String(item.price * item.counts)])
          })
        }
        count = count + order.shippingFee;
        const branchName =  organizationDatas.find((org) => org.id === order.organizationId)?.name;
        list.push(['配送料', `${order.orderNumber ? order.orderNumber : 'お客様番号未記入'}: ${branchName}`, '',String(order.shippingFee), String(1), String(order.shippingFee)]);
    });
    list.push(['小計', String(count)]);
    list.push(['消費税', String(Math.round(count * 0.1))]);
    list.push(['合計', String(Math.round(count*1.1))]);
    exportCSV(list, '請求書');
  }

  const rows: any[] = useMemo(() => {
    // ドキュメントID,  PDFダウンロード　CSVダウンロード
    return mfBillings.map((mfBilling) => {
      const year = String(mfBilling.mfbillingId).slice(0, 4);
      const month = String(mfBilling.mfbillingId).slice(4, 6);
      return {
        id: mfBilling.billingId,
        date: `${year}年${month}月`,
        pdf: <Button variant='outlined' disableElevation onClick={() => onClickDownloadBilling(String(mfBilling.mfbillingId))}>PDFダウンロード</Button>,
        csv: <Button variant='outlined' disableElevation onClick={() => onClickDonwloadCSV(String(mfBilling.mfbillingId))}>CSVダウンロード</Button>,
      }
    })
  }, [mfBillings]);
  
  return (
    <FullPaper>
      <StyledTitle>請求書ページ</StyledTitle>
      {mfBillings && mfBillings.length > 0 && mfBillings[0].billingId ? 
      <InvoiceTable rows={rows}/> :
        <NoHistoryWrap>
          <div style={{ marginBottom: 24 }}>
            <NoHistory />
          </div>
          <div style={{fontSize: '1rem', marginTop: 24}}>{'請求書はありません'}</div>
        </NoHistoryWrap>}
        <SimpleBackdrop
      open={disabled}
      message={'請求書をダウンロード中です。しばらくお待ちください...'}
      />
    </FullPaper>
  );
};

export default InvoicePage;

const StyledTitle = styled(Title)`
  margin-top: 96px;
  margin-left: ${(props) => props.theme.spacing(3)};
`;
const NoHistoryWrap = styled.div`
  width: 100%;
  margin: 96px auto 0 auto;
  text-align: center;
`;

type InvoiceTableProps = {
  rows: any[]
}

const InvoiceTable= (props: InvoiceTableProps) => {
  const rows = props.rows;
  return (
    <div style={{marginTop: 40, padding: '0px 8px'}}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>請求月</TableCell>
          <TableCell>PDFダウンロード</TableCell>
          <TableCell>CSVダウロード</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => {
          return (
            <TableRow key={row.id}>
              <TableCell>{row.date}</TableCell>
              <TableCell>{row.pdf}</TableCell>
              <TableCell>{row.csv}</TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
    </div>
  )
}
