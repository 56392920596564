import { firestore } from '../index';
import { collection, query, where, getDocs, documentId,} from "firebase/firestore";

export const newsRef = collection(firestore, 'news');

export const firestoreNews = async(newsId: string) => {
    const q = await query(newsRef, where(documentId(), '==', newsId));
    const newsSnapShot = await getDocs(q);
    if(!newsSnapShot.empty){
        const doc = newsSnapShot.docs[0]
        const data = doc.data();
        return data;
    };
}

// export const updateOrganizationsRef = async (value: Organization, organizationId: string) => {
//     await setDoc(doc(firestore, "organizations", organizationId), value);
//   };
// const q = await query(organizationRef, where(documentId(), '==', customer.organizationId));
//     const organizationSnapShot = await getDocs(q);
//     if(!organizationSnapShot.empty){
//       const doc = organizationSnapShot.docs[0]
//       const data = doc.data() as Organization
//       dispatch(setOrganization({...data, organizationId: doc.id}));
//     };