import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Item } from 'common-types/item';

export const initItem: Item ={
  itemNumber: '',
  image_url:[],
  price: 0,
  currencyType: '',
  roll_1 : '',
  vertical_repeat: '',
  vertical_repeat_unit: '',
  patternType: '',
  stockType: '',
  sampleBookName: '',
  page: 0,
  expirationDate: '',
  makerName: '',
  discontinuedFlag: '', // 廃番
  fNumber: '',
  fireProofType: '',
  materialType: '',
  certificationNumber_noCombustible: '',
  certificationNumber_gypsumBoard: '',
  certificationNumber_semiIncombustibleBase: '',
  itemCode: '',
  archiveFlag: false,
  objectID: '',
  priceTableCreationDate: '',
  createAt: '',
  updatedAt: '',
  referencePrice: '',
  referenceCurrencyType: '',
  width: '',
  unit_1: '',
  roll_2: '',
  unit_2: '',
  widthCount: '',
  itemType: '',
  itemGroup: '',
  existsSample: '',
  itemTypeName: '',
  priceCode: '',
  purchasingPriceStock: '',
  purchasingPriceStockUnit: '',
  purchasingPrice: '',
  purchasingPriceUnit: '',
  costSheetCreationDate: '',
  originalSampleBookName: '',
  labelNumber: '',
  supplierCode: '',
  tossSampleBookCode: '',
  sequentialNumber: '',
  formatSummary: '',
  isOrderable: false,
}

export const initialState: Item[] = [initItem]

const itemsSlice = createSlice({
  name: 'items',
  initialState,
  reducers: {
    setItems: (_state: Item[], action: PayloadAction<Item[]>) => {
      return action.payload;
    },
    resetItems: () => {
      return initialState;
    }
  }
});

export const { setItems, resetItems } = itemsSlice.actions;
export const itemsReducer = itemsSlice.reducer;
