import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type algoliaQueryType = {
  query: string,
  page: number,
  restrictSearchableAttributes: string[]
}

const initialState = {
  query: '',
  page: 0,
  restrictSearchableAttributes: ['']
}
const algoliaQuerySlice = createSlice({
  name: 'algoliaQuery',
  initialState,

  reducers: {
    setAlgoliaQuery: (_state: algoliaQueryType, action: PayloadAction<algoliaQueryType>) => {
      return action.payload;
    },
    resetAlgoliaQuery: () => {
      return initialState;
    }
  }
});

export const { setAlgoliaQuery, resetAlgoliaQuery } = algoliaQuerySlice.actions;
export const algoliaQueryReducer = algoliaQuerySlice.reducer;