export const firebaseConfig =
  process.env.REACT_APP_ENV === 'tecido-prod'
    ? {
        apiKey: 'AIzaSyCQT1WMAajk7K2CCFdOF6Gphr3OPIT1rIA',
        authDomain: 'tecido-prod.firebaseapp.com',
        databaseURL: 'https://tecido-prod.firebaseio.com',
        projectId: 'tecido-prod',
        storageBucket: 'tecido-prod.appspot.com',
        messagingSenderId: '315770862861',
        appId: '1:315770862861:web:bb4757df6c31faa5758444',
        measurementId: 'G-6CKYYS133Q',
      }
    : {
        apiKey: 'AIzaSyC8lYzSciRaumJN-oHiDLdGNFtxWEGHV1I',
        authDomain: 'tecido-stg.firebaseapp.com',
        databaseURL: 'https://tecido-stg.firebaseio.com',
        projectId: 'tecido-stg',
        storageBucket: 'tecido-stg.appspot.com',
        messagingSenderId: '249077275456',
        appId: '1:249077275456:web:f0240e9c209cf16f4d78a5',
      };
export const algoliaSearchKey = process.env.REACT_APP_ALGOLIA_SEARCH_KEY;
export const algoliaId = process.env.REACT_APP_ALGOLIA_APPLICATION_ID;
export const algoliaIndexId = window.location.hostname === 'localhost' ? 'items-prod' : process.env.REACT_APP_ENV === 'tecido-prod' ? 'items-prod' : 'items';
export const tayoriFormUrl =  'https://tayori.com/form/347e34796e78b489db95f362839416aad4cab73d/';
export const termUrl = 'https://firebasestorage.googleapis.com/v0/b/tecido-prod.appspot.com/o/public%2F%E5%88%A9%E7%94%A8%E8%A6%8F%E7%B4%84_20220707.pdf?alt=media&token=e321b12e-e4ac-4e21-9e84-f5e2538b9ddb';
export const privacyPolicyUrl = 'https://firebasestorage.googleapis.com/v0/b/tecido-prod.appspot.com/o/public%2F%E3%83%95%E3%82%9A%E3%83%A9%E3%82%A4%E3%83%8F%E3%82%99%E3%82%B7%E3%83%BC%E3%83%9B%E3%82%9A%E3%83%AA%E3%82%B7%E3%83%BC_20220708.pdf?alt=media&token=cf2ca206-072e-4958-876c-93268b204500'
export const arukabeManualUrl =  'https://heady-acai-c8b.notion.site/Arakuabe-98463d74963c49ffb40f9696d452bc27';