import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Item } from 'common-types/item';
import { CartItem, StockType } from 'common-types/order';

const initialState: CartItem[] = []

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addNormalItemCart: (state: CartItem[], action: PayloadAction<{item:Item, count: number, itemPrice: number, isExceedStock: boolean}>) => {
      const val = state.find(s=> s.itemId === action.payload.item.objectID && s.shippingType === 'normal');
      if (val) {
        // 既にnormalで追加されているので、数量を変更する
        const filterState = state.filter((doc) => !(doc.itemId === val.itemId && doc.shippingType === 'normal'));
        const newItem:CartItem = {
          ...val,
          counts: val.counts + action.payload.count
        }
        return [...filterState, newItem]
      } else {
        const item = action.payload.item;
        const price = action.payload.itemPrice
        const newState:CartItem = {
          itemId: item.objectID,
          counts: action.payload.count,
          shippingType: "normal",
          desiredDeliveryDate: '',
          memo: '',
          price,
          itemNumber: item.itemNumber,
          stockType: item.stockType as StockType,
          isExceedStock: action.payload.isExceedStock
        }
        return [...state, newState]
      }
    },
    addExpressItemCart: (state: CartItem[], action: PayloadAction<{item:Item, count: number, itemPrice: number, isExceedStock: boolean}>) => {
      const val = state.find(s=> s.itemId === action.payload.item.objectID && s.shippingType === 'express');
      if (val) {
         // 既にexpressで追加されているので、数量を変更する
        const filterState = state.filter((doc) => !(doc.itemId === val.itemId && doc.shippingType === 'express'));
        const newItem:CartItem = {
          ...val,
          counts: val.counts + action.payload.count
        };
        return [...filterState, newItem];
      } else {
        const item = action.payload.item;
        const price = action.payload.itemPrice
        const newState:CartItem = {
          itemId: item.objectID,
          counts: action.payload.count,
          shippingType: "express",
          desiredDeliveryDate: '',
          memo: '',
          price,
          itemNumber: item.itemNumber,
          stockType: item.stockType as StockType,
          isExceedStock: action.payload.isExceedStock
        }
        return [...state, newState];
      }
    },
    addCartItemNumber: (state: CartItem[], action: PayloadAction<{item:Item, count: number, isExceedStock: boolean}>) => {
      const filterItem = state.filter(s=> s.itemId !== action.payload.item.objectID);
      const val = state.find(s=> s.itemId === action.payload.item.objectID);
      if (val) {
        const updatedCount = action.payload.count + val.itemNumber
        return [...filterItem, {...val, itemNumber: updatedCount}]
      }
      return state
    },
    onChangeCart: (_: CartItem[], action: PayloadAction<CartItem[]>) => {
      return action.payload
    },
    removeCart: (state: CartItem[], action: PayloadAction<CartItem>) => {
      const newStates = state.filter(s=> !(s.itemId === action.payload.itemId && s.shippingType === action.payload.shippingType));
      return newStates;
    },
    resetCart: () => {
      return [];
    }
  }
});

export const { addNormalItemCart, addExpressItemCart, removeCart, resetCart, onChangeCart, addCartItemNumber } = cartSlice.actions;
export const cartReducer = cartSlice.reducer;
