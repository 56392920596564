import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MultiplicationRates } from 'common-types/organization';

export type StateMultiplicationRates = Omit<MultiplicationRates, 'createAt' | 'updateAt'> &
{
  createAtMillis: number,
  updateAtMillis: number
};

const initialState: StateMultiplicationRates= {
    itemCode001: 100,
    itemCode002: 100,
    itemCode003: 100,
    itemCode004: 100,
    itemCode005: 100,
    itemCode006: 100,
    itemCode007: 100,
    itemCode008: 100,
    itemCode009: 100,
    itemCode010: 100,
    createAtMillis: 0,
    updateAtMillis: 0,
  }

  const multiplicationRatesSlice = createSlice({
    name: 'multiplicationRates',
    initialState,

    reducers: {
      setMultiplicationRates: (_state: StateMultiplicationRates, action: PayloadAction<StateMultiplicationRates>) => {
        return action.payload;
      },
      resetMultiplicationRates: () => {
        return initialState;
      }
    }
  });

export const { setMultiplicationRates, resetMultiplicationRates } = multiplicationRatesSlice.actions;
export const multiplicationRatesReducer = multiplicationRatesSlice.reducer;
