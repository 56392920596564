import { firestore } from '../index';
import { Organization } from '../../../../common-types/organization';
import { collection, query, where, getDocs, setDoc, doc, addDoc, orderBy } from "firebase/firestore";

export const organizationRef = collection(firestore, 'organizations');

export const createOrganizationsRef = async (value: Organization) => {
  const q = await query(organizationRef, where('ownerId', '==', value.ownerId));
  const organizationSnapShot = await getDocs(q);
  if(!organizationSnapShot.empty){
    throw new Error('exists organizations')
  };
  await addDoc(collection(firestore, "organizations"), value);
};

export const updateOrganizationsRef = async (value: Organization, organizationId: string) => {
  await setDoc(doc(firestore, "organizations", organizationId), value);
};

export const getMultiplicationRates = async(organizationId: string) => {
  const snapshot = await getDocs(collection(firestore, "organizations", organizationId, "settings"));
  return snapshot;
}

export const getMfBillings = async(organizationId: string) => {
  const mfBillingsRef = collection(firestore, "organizations", organizationId, "mfbillings");
  // createdAtでソート
  const q = query(mfBillingsRef, orderBy("createAt", "desc"));
  const snapshot = await getDocs(q);
  return snapshot;
}

// FIXME: テストデータなので、連携出来次第削除
const randomText = Math.random().toString(36).slice(-8);
export const testOrganizationData:Organization = {
    name: `${randomText}tecido`,//（必須）名前
    abbreviationName: `${randomText}abbreviationName`,//（必須）略称
    country: `${randomText}country`,// （必須）国
    postalCode: `${randomText}postalCode`,//（必須）郵便番号
    address: `${randomText}address`,//（必須）住所
    billingAddress: `${randomText}billingAddress`,//（必須）請求先
    paymentTerm: `${randomText}paymentTerm`,// （必須）支払い条件
    ownerId : `${randomText}ownerId`,// （必須）責任者
    phoneNumer: "8080808080",//（任意）電話番号
    faxNumber:"8080808080",//（任意）FAX番号
    taxIncludedClassification: `${randomText}taxIncludedClassification`, //（任意）税込み区分
    departmentInCharge: `${randomText}departmentInCharge`,// 担当部署
    chargedPerson: `${randomText}chargedPerson`,// 担当者
    email: `${randomText}@gmail.com`,// (自動）メールアドレス
    memo: `${randomText}memo`,// (自動）メモ
    placeOfDepature: `${randomText}placeOfDepature`,// 発地
    placeOfDestination: `${randomText}placeOfDestination`,// 仕向地
    sellingPrice: 2000,// 商品建値
    originPrice: 3000,// 建値
    paymentRule: `${randomText}paymentRule`,// 支払い条件
    initialCurrency: `${randomText}initialCurrency`,// 初期通貨
    inChargedPerson: `${randomText}inChargedPerson`,// 自社担当者
    inSubChargedPerson: `${randomText}inSubChargedPerson`,// 自社担当者（SUB）
    organizationType: "existing", // existing: 既存（TOSSから持ってきたユーザ） new：新規
    prefecture: "東京都",
    departmentId: '',
}
