import { useEffect, useCallback } from 'react';
import { useAppDispatch } from '../redux';
import { auth } from './index';
import { User } from 'firebase/auth';
import { QueryDocumentSnapshot, DocumentData } from 'firebase/firestore';
import { login, logout } from '../redux/logined';
import { resetCustomerData, setCustomerData } from '../redux/customerData';
import { resetOrganization, setOrganization } from '../redux/organization';
import { resetNews, setNews } from '../redux/news';
import { resetOrder, setOrder, StateOrder } from '../redux/order';
import { StateCustomer } from '../redux/customerData'
import { resetCustomerList, setCustomerList } from '../redux/customerList';
import { Order } from '../../../common-types/order';
import { Customer } from '../../../common-types/customer';
import { customerRef } from '../firebase/firestore/customer';
import { getMfBillings, organizationRef, getMultiplicationRates } from '../firebase/firestore/organizations';
import { newsRef } from '../firebase/firestore/news';
import { orderRef } from '../firebase/firestore/order';
import { query, where, getDocs, documentId, onSnapshot, orderBy} from "firebase/firestore";
import { MfBilling, Organization, MultiplicationRates } from 'common-types/organization'
import { resetMfBillings, setMfBillings, StateMfBilling } from '../redux/mfbillings';
import { resetMultiplicationRates, setMultiplicationRates } from '../redux/multiplicationRates';
import { resetCart } from '../redux/cart';
import { setRead } from '../redux/initRead';
import functions from './functions';
import { StockState, resetStocks, setStocks } from '../redux/stocks';

export const FirebaseToRedux = () => {
  const dispatch = useAppDispatch();

  const observeCustomer = useCallback(async(email: string | null):Promise<StateCustomer| undefined>  => {
    if (!email) return undefined;
    const q = await query(customerRef, where('email', '==', email));
    const customerSnapShot = await getDocs(q);
    if(!customerSnapShot.empty){
      const doc = customerSnapShot.docs[0]
      const data = doc.data() as Customer
      const stateCustomer = setCustomerState(data, doc)
      dispatch(setCustomerData(stateCustomer));
      return stateCustomer
    };
  },[dispatch]);

  const observeOrganization = useCallback(async(customer: StateCustomer| undefined) => {
    if (!customer) return;
    const q = await query(organizationRef, where(documentId(), '==', customer.organizationId));
    const organizationSnapShot = await getDocs(q);
    if(!organizationSnapShot.empty){
      const doc = organizationSnapShot.docs[0]
      const data = doc.data() as Organization
      dispatch(setOrganization({...data, organizationId: doc.id}));
    } else {
      dispatch(logout());
    };
  },[dispatch]);

  const getMultiplicationRatesData = useCallback(async(organizationId: string | null) => {
    if(!organizationId) return;
    const multiplicationRatesSnapshot = await getMultiplicationRates(organizationId);
    if(!multiplicationRatesSnapshot.empty){
      const doc = multiplicationRatesSnapshot.docs[0]
      const {createAt, updateAt, ...data} = doc.data() as MultiplicationRates;
      dispatch(setMultiplicationRates({
        ...data,
        createAtMillis: createAt ? createAt.toMillis() : 0,
        updateAtMillis: updateAt ? updateAt.toMillis() : 0,
      }));
    }
  },[dispatch]);


  const getMfBillingsData = useCallback(async(organizationId: string | null) => {
    if(!organizationId) return;
    const snapshot = await getMfBillings(organizationId);
    const list: StateMfBilling[] =[];
    if(!snapshot.empty){
      snapshot.docs.forEach((doc) => {
        const mfbillingData = doc.data() as MfBilling;
        list.push({
          pdfUrl: mfbillingData.pdfUrl,
          billingId:mfbillingData.billingId,
          createAtMillis: mfbillingData.createAt.toMillis(),
          updateAtMillis: mfbillingData.updateAt.toMillis(),
          mfbillingId: doc.id,
        })
      });
      dispatch(setMfBillings(list));
    }
  },[dispatch]);


  const observeNews = useCallback(async(customer: StateCustomer| undefined) => {
    if (!customer) return;
    const q = await query(newsRef, orderBy('publishedAt', 'desc'));
    const newsSnapShot = await getDocs(q);
    let allNews: any[] = [];
    newsSnapShot.forEach((doc) => {
      let eachNews = doc.data();
      eachNews['newsId'] = doc.id;
      allNews.push(eachNews);
    });
    dispatch(setNews(allNews));
  },[dispatch]);

  const observeOrder = useCallback((customer: StateCustomer| undefined) => {
    if (!customer) return;
    const q = query(orderRef, where('organizationId', '==', customer.organizationId));
    onSnapshot(q,(snapshot) => {
      const list: StateOrder[] = [];
      snapshot.forEach((doc) => {
        const data = doc.data() as Order
        const {createAt, updateAt, ...params} = data
        list.push({
          ...params,
          createAtMillis: createAt.toMillis(),
          updateAtMillis: updateAt.toMillis(),
          id: doc.id
        });
      });
      dispatch(setOrder(list));
    });
  }, [dispatch])

  const observeCustomerList = useCallback(async(customer: StateCustomer| undefined) => {
    // updateAtの同期とsort関数の切り出しと型の定義（リファクタリング対象）
    if (!customer?.email) return undefined;
    const q = await query(customerRef, where('organizationId', '==', customer.organizationId));
    onSnapshot(q, (snapshot) => {
      let customerList:StateCustomer[] = [];
      snapshot.forEach(async(doc) => {
        const stateCustomer = setCustomerState(doc.data() as Customer, doc);
        customerList = [...customerList, stateCustomer]
      });
      const sortedList = customerList.sort((a:StateCustomer, b:StateCustomer)=> {
        if (a.name > b.name) {
          return 1;
        } else {
          return -1;
        }
      })
      dispatch(setCustomerList(sortedList));
    });
  },[dispatch]);

  const getStocks = useCallback(async() => {
    const res= await functions.getAllStocks();
    if(res){
      dispatch(setStocks(res as StockState[]));
    }
  },[])

  const observeAuth = useCallback(() => {
    auth.onAuthStateChanged(async(user: User | null) => {
      if (user) {
        dispatch(login());
        const customer = await observeCustomer(user.email);
        await observeOrganization(customer);
        await observeCustomerList(customer);
        await observeOrder(customer);
        await observeNews(customer);
        await getMultiplicationRatesData(customer?.organizationId ? customer?.organizationId : null)
        getMfBillingsData(customer?.organizationId ? customer?.organizationId : null);
      } else {
        dispatch(logout());
        dispatch(resetMfBillings());
        dispatch(resetCart());
        dispatch(resetNews());
        dispatch(resetOrder());
        dispatch(resetMultiplicationRates());
        dispatch(resetOrganization());
        dispatch(resetCustomerList());
        dispatch(resetCustomerData());
        dispatch(resetStocks());
      }
      setTimeout(() => {
        dispatch(setRead());
      }, 500);
      if(user){
        await getStocks();
      }
    });
  }, [dispatch, observeCustomer, observeOrganization, observeCustomerList, observeOrder, observeNews, getMultiplicationRatesData, getMfBillingsData]);


  useEffect(() => {
    observeAuth();
  }, [observeAuth]);

  return null;
};

const setCustomerState = (cus : Customer, doc: QueryDocumentSnapshot<DocumentData>) =>{
  return {
    ...cus,
    createAtMillis: cus.createAt.toMillis(),
    updateAtMillis: cus.updateAt? cus.updateAt.toMillis() : null,
    customerId: doc.id,
  }
}
