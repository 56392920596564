import { signOut, createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from './index';

export const logout = async () => {
  await signOut(auth)
    .then(() => {
      return true;
    });
};

export const createUser = async(email: string, password: string)=> {
  const response =  await createUserWithEmailAndPassword(auth, email, password).catch((error) => {
    var errorCode = error?.code;
    var errorMessage = error?.message;
    // ..
  });
  return response
}