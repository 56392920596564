import { createSlice } from '@reduxjs/toolkit';

const loginedSlice = createSlice({
  name: 'logined',
  initialState: false,

  reducers: {
    login: () => {
      return true;
    },
    logout: () => {
      return false;
    },
  },
});

export const { login, logout } = loginedSlice.actions;
export const loginedReducer = loginedSlice.reducer;
