import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Organization } from 'common-types/organization';

export type StateOrganization = Organization & {organizationId: string}

const initialState: StateOrganization= {
  name:"",//（必須）名前
  abbreviationName: "",//（必須）略称
  country: "",// （必須）国
  postalCode: "",//（必須）郵便番号
  address: "",//（必須）住所
  billingAddress: "",//（必須）請求先
  paymentTerm: "",// （必須）支払い条件
  ownerId : "",// （必須）責任者
  phoneNumer:  null,//（任意）電話番号
  faxNumber: null,//（任意）FAX番号
  taxIncludedClassification: null, //（任意）税込み区分
  departmentInCharge: "",// 担当部署
  chargedPerson: "",// 担当者
  email: "",// (自動）メールアドレス
  memo: "",// (自動）メモ
  placeOfDepature: "",// 発地
  placeOfDestination: "",// 仕向地
  sellingPrice: 0,// 商品建値
  originPrice: 0,// 建値
  paymentRule: "",// 支払い条件
  initialCurrency: "",// 初期通貨
  inChargedPerson: "",// 自社担当者
  inSubChargedPerson: "",// 自社担当者（SUB）
  organizationType: "existing", // existing: 既存（TOSSから持ってきたユーザ） new：新規
  prefecture: "", // 都道府県
  organizationId: "",
  departmentId: '',
}
const organizationSlice = createSlice({
  name: 'organization',
  initialState,

  reducers: {
    setOrganization: (_state: StateOrganization, action: PayloadAction<StateOrganization>) => {
      return action.payload;
    },
    resetOrganization: () => {
      return initialState;
    }
  }
});

export const { setOrganization, resetOrganization } = organizationSlice.actions;
export const organizationReducer = organizationSlice.reducer;
