export const CompanyIcon = () => (
	<svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
		<ellipse cx="18" cy="18.5" rx="18" ry="18.1957" fill="#D0CFCF" />
		<path d="M12 12.4348C12 11.8986 12.2107 11.3843 12.5858 11.0052C12.9609 10.626 13.4696 10.413 14 10.413H22C22.5304 10.413 23.0391 10.626 23.4142 11.0052C23.7893 11.3843 24 11.8986 24 12.4348V24.5652C24.2652 24.5652 24.5196 24.6717 24.7071 24.8613C24.8946 25.0508 25 25.308 25 25.5761C25 25.8442 24.8946 26.1013 24.7071 26.2909C24.5196 26.4804 24.2652 26.5869 24 26.5869H21C20.7348 26.5869 20.4804 26.4804 20.2929 26.2909C20.1054 26.1013 20 25.8442 20 25.5761V23.5543C20 23.2862 19.8946 23.0291 19.7071 22.8395C19.5196 22.65 19.2652 22.5435 19 22.5435H17C16.7348 22.5435 16.4804 22.65 16.2929 22.8395C16.1054 23.0291 16 23.2862 16 23.5543V25.5761C16 25.8442 15.8946 26.1013 15.7071 26.2909C15.5196 26.4804 15.2652 26.5869 15 26.5869H12C11.7348 26.5869 11.4804 26.4804 11.2929 26.2909C11.1054 26.1013 11 25.8442 11 25.5761C11 25.308 11.1054 25.0508 11.2929 24.8613C11.4804 24.6717 11.7348 24.5652 12 24.5652V12.4348ZM15 13.4456H17V15.4674H15V13.4456ZM17 17.4891H15V19.5109H17V17.4891ZM19 13.4456H21V15.4674H19V13.4456ZM21 17.4891H19V19.5109H21V17.4891Z" fill="white" />
	</svg>
);

export const OrderIcon = () => (
	<svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M18.334 30.8957L26.709 39.2707L40.6673 22.5208" stroke="#F35724" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M29.5006 57.4166C44.9186 57.4166 57.4173 44.9179 57.4173 29.4999C57.4173 14.082 44.9186 1.58325 29.5006 1.58325C14.0827 1.58325 1.58398 14.082 1.58398 29.4999C1.58398 44.9179 14.0827 57.4166 29.5006 57.4166Z" stroke="#F35724" stroke-width="3" />
	</svg>
);

export const ArukabeIcon = () => (
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		width="200px" height="75px" viewBox="0 0 362.353 60.654" enableBackground="new 0 0 362.353 60.654"
		xmlSpace="preserve">
		<g id="レイヤー_1">
			<g>
				<g>
					<path fillRule="evenodd" clipRule="evenodd" fill="#569E86" d="M339.174,12.484h10.695v4.422h-10.695V12.484L339.174,12.484z
				 M339.174,18.738h10.695v4.434h-6.959l-3.736-3.309V18.738L339.174,18.738z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#6080B2" d="M349.869,0v10.695h-4.429V0H349.869L349.869,0z M343.606,0
				v10.695h-4.433V0H343.606L343.606,0z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#AF3230" d="M351.657,23.172V12.484h4.43v10.688H351.657L351.657,23.172z
				 M357.921,23.172V12.484h4.432v10.688H357.921L357.921,23.172z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#D9AE35" d="M326.697,0h10.692v4.432h-10.692V0L326.697,0z M326.697,6.268
				h10.692v4.427h-8.575l-2.117-1.875V6.268L326.697,6.268z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#886390" d="M351.657,0h10.695v4.432h-10.695V0L351.657,0z M351.657,6.268
				h10.695v4.427h-10.695V6.268L351.657,6.268z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#CD5D00" d="M351.657,24.961h10.695v4.426h-10.695V24.961L351.657,24.961z
				 M357.011,35.655l-5.001-4.428h10.343v4.428H357.011L357.011,35.655z"/>
				</g>
				<g>
					<g>
						<path d="M59.377,18.576h9.431c4.287,0,7.547,0.286,9.547,1.029c4.23,1.6,6.745,5.259,6.745,9.89
					c0,2.058-1.06,10.49-14.233,10.49l14.805,19.64h-5.088l-14.747-19.64h-2.344v19.64h-4.115V18.576z M63.492,22.578v13.405
					c2.286,0,4.63,0.057,6.802,0.057c3.545,0,6.288-0.343,8.061-1.772c1.486-1.2,2.458-2.657,2.458-4.772
					c0-4.002-2.744-6.917-8.917-6.917H63.492z"/>
						<path d="M129.683,18.576h4.116v26.699c0,9.662-5.202,15.38-13.604,15.38s-13.604-5.718-13.604-15.38V18.576h4.116v26.07
					c0,3.202,0,6.061,2.172,8.747c1.771,2.23,4.23,3.258,7.316,3.258s5.545-1.028,7.316-3.258c2.172-2.687,2.172-5.545,2.172-8.747
					V18.576z"/>
						<path d="M156.604,18.576h4.114v15.379l16.403-15.379h5.544l-19.718,18.41l21.263,22.639h-5.432l-18.061-19.152v19.152h-4.114
					V18.576z"/>
						<path d="M258.601,59.624V18.576h8.689c8.631,0,12.574,4.23,12.574,10.005c0,3.601-1.714,6.689-5.258,8.633
					c5.715,1.944,8.631,5.488,8.631,10.634c0,6.117-4.627,11.777-13.377,11.777L258.601,59.624z M262.717,35.784h2.743
					c3.087,0,5.488-0.4,7.202-1.544c1.829-1.2,2.915-3.373,2.915-5.659c0-3.66-2.743-6.003-7.487-6.003h-5.373V35.784z
					 M262.717,55.624h5.591c7.637,0,10.643-3.061,10.643-7.719c0-2.688-1.485-5.203-4.002-6.575c-2.4-1.315-5.602-1.544-9.432-1.544
					h-2.8V55.624z"/>
						<path d="M303.269,18.576h23.438v4.002h-19.322v12.864h19.322v4.002h-19.322v16.179h19.322v4.002h-23.438V18.576z" />
					</g>
					<path d="M235.024,59.625h4.401l-19.149-41.049h-0.971L199.87,59.625h4.573l7.181-15.168h16.246L235.024,59.625z M213.548,40.392
				l6.215-13.126l6.19,13.126H213.548z"/>
					<path d="M35.155,59.625h4.4L20.407,18.576h-0.971L0,59.625h4.573l7.182-15.168h16.246L35.155,59.625z M13.679,40.392
				l6.215-13.126l6.19,13.126H13.679z"/>
				</g>
			</g>
		</g>
		<g id="レイヤー_2">
		</g>
		<g id="レイヤー_3">
		</g>
	</svg>

);

export const CloseSVG = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="16" cy="16" r="16" fill="#D0CFCF" />
		<path
			d="M16.7902 16.6204L20.3548 13.0637C20.5109 12.9076 20.5985 12.6958 20.5985 12.475C20.5985 12.2543 20.5109 12.0425 20.3548 11.8864C20.1987 11.7303 19.9869 11.6426 19.7662 11.6426C19.5454 11.6426 19.3337 11.7303 19.1776 11.8864L15.6214 15.4514L12.0652 11.8864C11.9091 11.7303 11.6973 11.6426 11.4766 11.6426C11.2558 11.6426 11.0441 11.7303 10.888 11.8864C10.7319 12.0425 10.6442 12.2543 10.6442 12.475C10.6442 12.6958 10.7319 12.9076 10.888 13.0637L14.4526 16.6204L10.888 20.1772C10.8103 20.2543 10.7487 20.346 10.7066 20.447C10.6645 20.548 10.6428 20.6564 10.6428 20.7658C10.6428 20.8753 10.6645 20.9836 10.7066 21.0847C10.7487 21.1857 10.8103 21.2774 10.888 21.3545C10.9651 21.4322 11.0568 21.4939 11.1578 21.536C11.2588 21.5781 11.3672 21.5997 11.4766 21.5997C11.586 21.5997 11.6944 21.5781 11.7954 21.536C11.8964 21.4939 11.9881 21.4322 12.0652 21.3545L15.6214 17.7894L19.1776 21.3545C19.2547 21.4322 19.3464 21.4939 19.4474 21.536C19.5484 21.5781 19.6568 21.5997 19.7662 21.5997C19.8756 21.5997 19.984 21.5781 20.085 21.536C20.186 21.4939 20.2777 21.4322 20.3548 21.3545C20.4325 21.2774 20.4941 21.1857 20.5362 21.0847C20.5783 20.9836 20.6 20.8753 20.6 20.7658C20.6 20.6564 20.5783 20.548 20.5362 20.447C20.4941 20.346 20.4325 20.2543 20.3548 20.1772L16.7902 16.6204Z"
			fill="white"
		/>
	</svg>
);

export const EmptyResult = () => (
	<svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="80" cy="80" r="80" fill="#F1EFEF"/>
<path d="M86.4452 91.1977C81.676 95.0078 75.6289 96.8473 69.5458 96.3382C63.4627 95.8291 57.8054 93.0102 53.7358 88.4603C49.6662 83.9104 47.4932 77.975 47.6631 71.873C47.8329 65.771 50.3328 59.9656 54.6492 55.6492C58.9656 51.3328 64.771 48.8329 70.873 48.6631C76.975 48.4932 82.9104 50.6662 87.4603 54.7358C92.0102 58.8054 94.8291 64.4627 95.3382 70.5458C95.8473 76.6289 94.0078 82.676 90.1976 87.4452L110.568 107.81C111.066 108.308 111.346 108.984 111.346 109.689C111.346 110.394 111.066 111.07 110.568 111.568C110.07 112.066 109.394 112.346 108.689 112.346C107.984 112.346 107.308 112.066 106.81 111.568L86.4506 91.1977H86.4452ZM90.1127 72.5365C90.1127 70.097 89.6322 67.6814 88.6987 65.4276C87.7651 63.1738 86.3968 61.126 84.6719 59.401C82.9469 57.6761 80.8991 56.3078 78.6453 55.3742C76.3915 54.4407 73.9759 53.9602 71.5365 53.9602C69.097 53.9602 66.6814 54.4407 64.4276 55.3742C62.1738 56.3078 60.126 57.6761 58.401 59.401C56.6761 61.126 55.3078 63.1738 54.3742 65.4276C53.4407 67.6814 52.9602 70.097 52.9602 72.5365C52.9602 77.4632 54.9173 82.1881 58.401 85.6719C61.8848 89.1556 66.6097 91.1127 71.5365 91.1127C76.4632 91.1127 81.1881 89.1556 84.6719 85.6719C88.1556 82.1881 90.1127 77.4632 90.1127 72.5365Z" fill="white"/>
</svg>

)

export const MemoIcon = () => {
	return (
		<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M14.625 2.375V10.25H10.25V14.625H2.375V2.375H14.625ZM14.625 0.625H2.375C1.4125 0.625 0.625 1.4125 0.625 2.375V14.625C0.625 15.5875 1.4125 16.375 2.375 16.375H11.125L16.375 11.125V2.375C16.375 1.4125 15.5875 0.625 14.625 0.625ZM8.5 10.25H4.125V8.5H8.5V10.25ZM12.875 6.75H4.125V5H12.875V6.75Z" fill="#343030" />
		</svg>
	)
}

export const NoCartIcon = () => {
	return(
		<svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="80" cy="80" r="80" fill="#F1EFEF"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M68.1087 91.8919H91.8925C92.681 91.8919 93.4371 92.2051 93.9947 92.7626C94.5522 93.3202 94.8654 94.0764 94.8654 94.8649C94.8654 95.6533 94.5522 96.4095 93.9947 96.9671C93.4371 97.5246 92.681 97.8378 91.8925 97.8378H65.1357C64.3472 97.8378 63.591 97.5246 63.0335 96.9671C62.476 96.4095 62.1627 95.6533 62.1627 94.8649V56.2162H59.1898C58.4013 56.2162 57.6451 55.903 57.0876 55.3454C56.53 54.7879 56.2168 54.0317 56.2168 53.2432C56.2168 52.4548 56.53 51.6986 57.0876 51.141C57.6451 50.5835 58.4013 50.2703 59.1898 50.2703H65.1357C65.9242 50.2703 66.6804 50.5835 67.2379 51.141C67.7955 51.6986 68.1087 52.4548 68.1087 53.2432V59.1922L68.3257 59.1892H97.6214C101.025 59.1892 103.784 61.85 103.784 65.1351C103.784 65.5692 103.734 66.0003 103.636 66.4254L100.214 81.2903C99.5895 84.0105 97.0863 85.9459 94.1965 85.9459H68.3257L68.1087 85.943V91.8919ZM66.6222 109.73C65.4395 109.73 64.3052 109.26 63.4689 108.424C62.6326 107.587 62.1627 106.453 62.1627 105.27C62.1627 104.088 62.6326 102.953 63.4689 102.117C64.3052 101.281 65.4395 100.811 66.6222 100.811C67.8049 100.811 68.9392 101.281 69.7755 102.117C70.6118 102.953 71.0817 104.088 71.0817 105.27C71.0817 106.453 70.6118 107.587 69.7755 108.424C68.9392 109.26 67.8049 109.73 66.6222 109.73ZM93.379 109.73C92.1962 109.73 91.062 109.26 90.2256 108.424C89.3893 107.587 88.9195 106.453 88.9195 105.27C88.9195 104.088 89.3893 102.953 90.2256 102.117C91.062 101.281 92.1962 100.811 93.379 100.811C94.5617 100.811 95.696 101.281 96.5323 102.117C97.3686 102.953 97.8384 104.088 97.8384 105.27C97.8384 106.453 97.3686 107.587 96.5323 108.424C95.696 109.26 94.5617 109.73 93.379 109.73ZM68.1087 65.1351V80H94.363L97.8384 65.1351H68.1087Z" fill="white"/>
		</svg>
	)
}

export const NoHistory = () => {
	return (
		<svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="80" cy="80" r="80" fill="#F1EFEF"/>
<g clip-path="url(#clip0_0_1)">
<path d="M100.708 58.7917V85.4167H85.9167V100.208H59.2917V58.7917H100.708ZM100.708 52.875H59.2917C56.0375 52.875 53.375 55.5375 53.375 58.7917V100.208C53.375 103.462 56.0375 106.125 59.2917 106.125H88.875L106.625 88.375V58.7917C106.625 55.5375 103.962 52.875 100.708 52.875ZM80 85.4167H65.2083V79.5H80V85.4167ZM94.7917 73.5833H65.2083V67.6667H94.7917V73.5833Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_0_1">
<rect width="71.3513" height="71.3513" fill="white" transform="translate(44.3242 44.3242)"/>
</clipPath>
</defs>
</svg>
	)
}



