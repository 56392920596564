import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { News } from 'common-types/news';

export const initNews: News= {
    title: "",
    content: "",
    isPublished: false,
    createdBy: "",
    updatedBy: null,
    updateAt: null,
    createAt: null,
    publishedAt: null,
    newsId: "",
}

export const initialState: News[] = [initNews]

const newsSlice = createSlice({
    name: 'news',
    initialState,
    reducers: {
        setNews: (_state: News[], action: PayloadAction<News[]>) => {
          return action.payload;
        },
        resetNews: () => {
          return initialState;
        }
      }
  });
  
  export const { setNews, resetNews } = newsSlice.actions;
  export const newsReducer = newsSlice.reducer;
  