import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StateCustomer, initialState } from './customerData';

const customerListSlice = createSlice({
  name: 'customerDataList',
  initialState: [initialState],
  reducers: {
    setCustomerList: (_state: StateCustomer[], action: PayloadAction<StateCustomer[]>) => {
      return action.payload;
    },
    resetCustomerList: () => {
      return [initialState];
    }
  }
});

export const { setCustomerList, resetCustomerList } = customerListSlice.actions;
export const customerListReducer = customerListSlice.reducer;
