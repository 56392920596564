import { functions as fs } from '../index';
import { CartItem, ShippingInfo } from 'common-types/order';
import { httpsCallable } from "firebase/functions";
import { Role } from 'common-types/roles';

export const functions = {
  insertOrder: async(cart: CartItem[], organizationId:string, shippingInfo: ShippingInfo)  => {
    const insertOrder = httpsCallable(fs, 'onCall-insertOrders');
    const result = await insertOrder({ cart, organizationId, shippingInfo})
      .catch((err) => {
        console.log('err onCall-updateItems', err);
        throw err;
      });
    return result;
  },
  createInitToken:  async(code: string)  => {
    const createInitToken = httpsCallable(fs, 'onCall-createInitToken');
    await createInitToken({code: code})
      .then((result) => {
        return result
      })
      .catch((err) => {
        console.log('err onCall-updateItems', err);
        throw err;
      });
  },
  createQuote:  async(cart: CartItem[],  shippingInfo: ShippingInfo)  => {
    const createQuote = httpsCallable(fs, 'onCall-createQuote');
    const response = await createQuote({cart: cart, shippingInfo: shippingInfo}).catch((err) => {
        console.log('err onCall-updateItems', err);
        throw err;
      });
    return response.data as {base64:string, quote_number: string}
  },
  getMFBilling:  async(date: string)  => {
    const getMFBilling = httpsCallable(fs, 'onCall-getMFBilling');
    const response = await getMFBilling({date: date}).catch((err) => {
        console.log('err onCall-getMFBilling', err);
        throw err;
      });
    return response;
  },
  testBillingBatch:  async()  => {
    const testBillingBatch = httpsCallable(fs, 'onCall-testBillingBatch');
    const response = await testBillingBatch().catch((err) => {
        console.log('err onCall-testBillingBatch', err);
        throw err;
      });
    return response
  },
  createRole:  async(role: Role)  => {
    const createRole = httpsCallable(fs, 'onCall-createRole');
    const response = await createRole(role).catch((err) => {
        console.log('err onCall-createRole', err);
        throw err;
      });
    return response
  },
  checkAuthEmail:  async(email: string)  => {
    const checkAuthEmail = httpsCallable(fs, 'onCall-checkAuthEmail');
    const response = await checkAuthEmail({email: email}).catch((err) => {
        console.log('err onCall-createRole', err);
        throw err;
      });
    return response.data;
  },
  getAllStocks:  async()  => {
    const stocks = httpsCallable(fs, 'onCall-getTossStocks');
    const response = await stocks().catch((err) => {
        console.log('err onCall-getTossStocks', err);
        return null;
      });
    return response?.data;
  },
};
export default functions;
