import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { logout as reduxLogout } from '../../redux/logined';
import { CloseSVG } from "../../icons/SvgIcon"
import { useDispatch } from 'react-redux';
import { logout } from '../../firebase/auth';
import MenuIcon from '@mui/icons-material/Menu';
import styled from 'styled-components';
import { tayoriFormUrl } from '../../config'

export const SpMenu = () => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;
  const dispatch = useDispatch();

  const movePage = (path: string) => {
    setOpen(false);
    history.push(path);
  };

  const onClose = () => setOpen(false);

  const Logout = async () => {
    await logout()
      .then(() => {
        dispatch(reduxLogout());
      })
      .catch((err) => console.log(err));
  };

  return (
    <Container>
      {!open && <MenuIcon style={{ marginRight: '10px' }} onClick={() => setOpen(true)} />}
      {open && (
        <Overlay>
          <Wrapper>
            <CloseIcon onClick={onClose}>
              <CloseSVG />
            </CloseIcon>
            <MenuItem active={pathname === '/'} onClick={() => movePage('/')}>
              商品情報一覧
            </MenuItem>
            <MenuItem active={pathname === '/cart'} onClick={() => movePage('/cart')}>
              カート
            </MenuItem>
            <MenuItem
              active={pathname === '/order-history'}
              onClick={() => movePage('/order-history')}
            >
              注文履歴
            </MenuItem>
            <MenuItem active={pathname === '/settings/company'} onClick={() => movePage('/settings/company')}>
              企業情報
            </MenuItem>
            <MenuItem
              active={pathname === '/settings/account'}
              onClick={() => movePage('/settings/account')}
            >
              アカウント管理
            </MenuItem>
            <MenuItem
              active={pathname === '/invoice'}
              onClick={() => movePage('/invoice')}
            >
              請求書
            </MenuItem>
            <MenuItem
              active={false}
              onClick={() =>  window.open(tayoriFormUrl, '_blank')}
            >
              お問い合わせ
            </MenuItem>
            <MenuItem active={false} onClick={() => Logout()}>
              ログアウト
            </MenuItem>
          </Wrapper>
        </Overlay>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: none;
  @media screen and (max-width: 650px) {
    display: block;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: #fff;
  overflow: auto;
  z-index: 99;
`;

const Wrapper = styled.div`
  margin: 160px 60px;
  position: relative;
  text-align: left;
`;

const CloseIcon = styled.div`
  position: absolute;
  top: -30%;
  right: -10%;
`;

const MenuItem = styled.div<{ active: boolean }>`
  font-weight: 600;
  font-size: 18px;
  color: ${({ active }) => (active ? '#000' : '#B1B0AE')};
  margin-bottom: 30px;
  &:hover {
    color: #000;
  }
`;
