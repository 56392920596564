import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type StockState = {
  itemCode: string;
  stocks: Array<{rotNumber: string, stocks: number, salesNoteNo:string}>
}

export const initialState:StockState[] = [{
    itemCode: "",
    stocks: [
      {
        rotNumber: "",
        stocks: 0,
        salesNoteNo: "",
      }
    ]
}]


const stocksSlice = createSlice({
    name: 'stocks',
    initialState,
    reducers: {
        setStocks: (_state: StockState[], action: PayloadAction<StockState[]>) => {
          return action.payload;
        },
        resetStocks: () => {
          return initialState;
        }
      }
  });
  
  export const { setStocks, resetStocks } = stocksSlice.actions;
  export const stocksReducer = stocksSlice.reducer;
  