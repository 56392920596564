import { FullPaper } from '../atoms/Paper';
import {Title, SubTitle, Text} from '../atoms/Typography';
import styled from 'styled-components';

const NotFoundPage = () => {
    return(
        <FullPaper>
            <StyledTitle>ページが見つかりません</StyledTitle>
        </FullPaper>
    )

}

export default NotFoundPage;



const StyledTitle = styled(Title)`
  margin-left: ${(props) => props.theme.spacing(3)};
`;

