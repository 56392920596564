import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';
import { Text } from './Typography';

type Props = {
  open: boolean;
  message?: string
}

export const SimpleBackdrop: React.VFC<Props> =(props) => {
  const { open, message } = props;
  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
      <Wrap>
        <CircularProgress color="inherit" />
        <div style={{marginTop: 24}}><Text style={{fontWeight: 700, fontSize: 16}}>{message ? message : ''}</Text></div>
        </Wrap>
      </Backdrop>
    </div>
  );
}

const Wrap = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`;

